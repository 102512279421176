<template>
  <div>
    <page-header/>
    <div class="h1">404</div>
    <page-footer/>
  </div>
</template>
<script>
  import PageHeader from '../../components/PageHeader';
  import PageFooter from '../../components/PageFooter';

  export default {
    name: 'Page404',
    components: {PageFooter, PageHeader}
  };
</script>
<style scoped lang="less">
  .h1{padding:200px;text-align:center;font-size:28px;}
</style>
